body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Helvetica Neue Light', Arial !important;
  background: black !important;
}

html,
body {
  width: 100% !important;
}

input {
  font-family: 'Helvetica Neue Light', Arial !important;
}

.ant-table-thead {
  font-family: 'Helvetica Neue Light', Arial !important;
}

.ant-table-tbody {
  font-family: 'Helvetica Neue Light', Arial !important;
}

/* .ant-table-thead>tr>th {
  font-weight: 500 !important;
  font-size: 14px;
} */

h1,
h2,
h3,
h4,
h5,
span,
h6 {
  font-family: 'Helvetica Neue Light', Arial !important;
}

/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: black;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1E2126;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #121212;
}
